import React from 'react';
import './m_aboutUs.scss'
import './AboutUs.scss';

const About = () => {
    return (
        <section className="_aboutUs_container" id="about">
            <div className='_aboutUs_content'>
                <div className='_title'>
                    <div className="_section_title">关于我们</div>
                    <div className="_en">/ABOUT</div>
                    <div className="_line"></div>
                </div>
                <div className='_introduction _item'>
                    <div className='_text'>
                        <div className='_text_title'>
                            01
                            <span className='_sub'> / 简介</span>
                        </div>
                        <div className='_text_content'>杭州加密矩阵科技有限公司成立于2018年5月，是工信部审核通过的全国第二批区块链公司。成立两年以来拥有四项发明专利，目前已成为国内综合实力较强的专业跨链网关技术服务商。</div>
                        <div className='_text_content'>团队拥有行业领先的边缘计算和分布式存储的研发能力，在区块链基础 设施如跨链、BaaS平台、共识算法、隐私保护、智能合约等领域拥有 核心技术与专利</div>
                        <div className='m_text_content'>杭州加密矩阵科技有限公司成立于2018年5月，是工信部审核通
                        过的全国第二批区块链公司。成立两年以来拥有四项发明专
                        利，目前已成为国内综合实力较强的专业跨链网关技术服务
                        商。团队拥有行业领先的边缘计算和分布式存储的研发能力，
                        在区块链基础设施如跨链、BaaS平台、共识算法、隐私保护、
                        智能合约等领域拥有核心技术与专利。</div>
                    </div>
                    <div className='_img'>
                        <img src={require("../../../assets/image/new/introduction.png")} alt=""/>
                    </div>
                </div>
                <div className='_team _item'>
                    <div className='_img'>
                        <img src={require("../../../assets/image/new/team.png")} alt=""/>
                    </div>
                    <div className='_text'>
                        <div className='_text_title'>
                            02
                            <span className='_sub'> / 团队</span>
                        </div>
                        <div className='_text_content'>加密矩阵团队技术骨干由阿里、百度等知名公司成员组成，系统架构师与软件开发工程师占据公司总人数 80% 以上，在资产管理、资产交易、支付、电子信用证、数字钱包、供应链金融等场景拥有全方位、快捷、安全的解决方案服务能力</div>
                    </div>
                </div>
                <div className='_mission _item'>
                    <div className='_text'>
                        <div className='_text_title'>
                            03
                            <span className='_sub'> / 使命</span>
                        </div>
                        <div className='_text_content'>用技术链接信任</div>
                    </div>
                    <div className='_img'>
                        <img src={require("../../../assets/image/new/mission.png")} alt=""/>
                    </div>
                </div>
                <div className='_honor'>
                    <div className='_text_title'>
                        04
                        <span className='_sub'> / 荣誉</span>
                    </div>
                    <div className='_honor_line1'>
                        <div className='_honor_item'>
                            <img className='_honor_bg' src={require("../../../assets/image/new/honor_bg.svg")} alt=""/>
                            <img className='_honor_certificate' src={require("../../../assets/image/new/honor1.png")} alt=""/>
                        </div>
                        <div className='_honor_item'>
                            <img className='_honor_bg' src={require("../../../assets/image/new/honor_bg.svg")} alt=""/>
                            <img className='_honor_certificate' src={require("../../../assets/image/new/honor2.png")} alt=""/>
                        </div>
                        <div className='_honor_item'>
                            <img className='_honor_bg' src={require("../../../assets/image/new/honor_bg.svg")} alt=""/>
                            <img className='_honor_certificate' src={require("../../../assets/image/new/honor3.png")} alt=""/>
                        </div>
                    </div>
                    <div className='_honor_line2'>
                        <div className='_honor_item'>
                            <img className='_honor_bg' src={require("../../../assets/image/new/honor_bg.svg")} alt=""/>
                            <img className='_honor_certificate' src={require("../../../assets/image/new/honor4.png")} alt=""/>
                        </div>
                        <div className='_honor_item'>
                            <img className='_honor_bg' src={require("../../../assets/image/new/honor_bg.svg")} alt=""/>
                            <img className='_honor_certificate' src={require("../../../assets/image/new/honor5.png")} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;
