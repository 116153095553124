import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import About from './About/AboutUs';
import Slider from '../../assets/components/Slider'
import Bottom from '../../assets/components/Bottom';

import './m_index.scss';
import './index.scss';

const Home = () =>  {
    return (
        <div className='_home' id="header">
            <div className="_home_content">
                <section className='_title'>
                    <img className='_title_bg' src={require("../../assets/image/new/bg.png")} alt=""/>
                    <img className='m_title_bg' src={require("../../assets/image/new/m_bg.svg")} alt=""/>
                    <div className='_title_content'>
                        <div className='_line1'>加密矩阵</div>
                        <div className='_line2'>用技术链接信任</div>
                    </div>
                </section>
                <section id="tech" className='_coreTech'>
                    <div className='_coreTech_text'>
                        <div className="_section_title">核心技术</div>
                        <div className="_en">/ TECHNOLOGY</div>
                        <div className="_line"></div>
                    </div>
                    <div className='_coreTech_content'>
                        <div className='_LeagugChain'>
                            <img className='_icon' src={require("../../assets/image/new/leagugchain.svg")} alt=""/>
                            <div className='_content_title'>隐私计算</div>
                            <div className='_content_text'>实现了数据所有权与使用权分离，保证数据安全、合规、合理的有效使用。</div>
                            <Link to={'/leaguechain'} className="_menu_link" >
                                <div className='_content_button'>
                                    查看详情
                                </div>
                            </Link>
                        </div>
                        <div className='_gateway'>
                            <img className='_icon' src={require("../../assets/image/new/gateway.svg")} alt=""/>
                            <div className='_content_title'>跨链网关</div>
                            <div className='_content_text'>一种基于门限签名和零知识证明方案的允许异构的资产交换、信息互通及服务互补的跨链技术，专注于打通资产与信息孤岛，从而实现各个区块链项目之间强交互性的。</div>
                            <Link to={'/gateway'} className="_menu_link" >
                                <div className='_content_button'>
                                    查看详情
                                </div>
                            </Link>
                        </div>
                        <div className='_storage'>
                            <img className='_icon' src={require("../../assets/image/new/storage.svg")} alt=""/>
                            <div className='_content_title'>分布式存储</div>
                            <div className='_content_text'>在IPFS生态系统建设方面，加密矩阵构建了基于IPFS协议的视频存储及传输分布式系统，以提高视频数据传输效率，数据传输速度以及降低存储成本。</div>
                            <Link to={'/storage'} className="_menu_link" >
                                <div className='_content_button'>
                                    查看详情
                                </div>
                            </Link>
                        </div>
                        <div className='_Iot'>
                            <img className='_icon' src={require("../../assets/image/new/Iot.svg")} alt=""/>
                            <div className='_content_title'>工业互联网</div>
                            <div className='_content_text'>能够确保共识物联终端所采集数据的安全可信以及终端设备的智能化，同时加速推进工业物联网领域数据市场的商用化。</div>
                            <Link to={'/iot'} className="_menu_link" >
                                <div className='_content_button'>
                                    查看详情
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
                <About />
                <section className='_news_cooperation' id="news">
                    <div className='_news'>
                        <div className='_title_line'>
                            <div className='_news_title'>
                                <div className="_section_title">公司动态</div>
                                <div className="_en">/ NEWS</div>
                                <div className="_line"></div>
                            </div>
                            <Link to={'/articleList'} className='_more'>
                                <div className="_more_text">查看更多</div>
                                <img className='_more_icon' src={require("../../assets/image/new/arrow-right.svg")} alt=""/>
                            </Link>
                        </div>
                        <div className='_news_content'>
                            <Link to={'/articedetail/0'} className='_news_item'>
                                <div className="_icon_border">
                                    <img className='_icon' src={require("../../assets/image/new/top30.webp")} alt=""/>
                                </div>
                                <div className='_content_title'>加密矩阵入选“2020中国区块链应用TOP30企业”榜单</div>
                                <div className='_content_text'>加密矩阵的区块链底层技术解决方案在大幅提升底层技术可靠性的同时，实现了网络能效的最大化，和运营管理的自动化、简单化，目前已帮助多所的区块链运营商和合作伙伴取得商业成功，保障可持续性发展。</div>
                                <div className="_menu_link" >
                                    <div className='_content_button'>
                                        查看详情
                                    </div>
                                </div>
                            </Link>
                            <Link to={'/articedetail/1'} className='_news_item'>
                                <div className="_icon_border">
                                    <img className='_icon' src={require("../../assets/image/new/establish.webp")} alt=""/>
                                </div>
                                <div className='_content_title'>加密矩阵子公司受邀成为宁波市区块链专委会成员单位</div>
                                <div className='_content_text'>此次受邀成为宁波市区块链专委会成员单位，通过建设和布局宁波区块链城市专网，是实现底层区块链服务网络和各生态应用链的无缝对接和整合互通的一大步，由此能够更好更快地推动区块链技术在更多实际场景中落地。</div>
                                <div className="_menu_link" >
                                    <div className='_content_button'>
                                        查看详情
                                    </div>
                                </div>
                            </Link>
                            {/*<div className='_news_item'>
                                <div className="_icon_border">
                                    <img className='_icon' src={require("../../assets/image/new/news-0.png")} alt=""/>
                                </div>
                                <div className='_content_title'>习近平向2020中国5G+工业互联网大会致贺信</div>
                                <div className='_content_text'>2020中国5G+工业互联网大会20日在湖北省武汉市开幕。中共中央总书记、国家主席、中央军委主席习近平发来贺信，向大会的召开表示热烈祝贺。</div>
                                <Link to={'/articedetail/0'} className="_menu_link" >
                                    <div className='_content_button'>
                                        查看详情
                                    </div>
                                </Link>
                            </div>
                            <div className='_news_item'>
                                <div className="_icon_border">
                                    <img className='_icon' src={require("../../assets/image/new/storagesummit.png")} alt=""/>
                                </div>
                                <div className='_content_title'>2020宁波分布式存储峰会落幕加密矩阵是主办方</div>
                                <div className='_content_text'>8月28日上午，加密矩阵主办的以“助力新基建、共享新机遇”为主题的宁波分布式存储峰会，在浙江慈溪市召开。</div>
                                <Link to={'/articedetail/1'} className="_menu_link" >
                                    <div className='_content_button'>
                                        查看详情
                                    </div>
                                </Link>
                            </div>*/}
                            <Link to={'/articedetail/2'} className='_news_item'>
                                <div className="_icon_border">
                                    <img className='_icon' src={require("../../assets/image/new/innovation.webp")} alt=""/>
                                </div>
                                <div className='_content_title'>加密矩阵荣获2020年度创新技术奖</div>
                                <div className='_content_text'>此次参评的企业和机构都是来自全国实力雄厚的区块链各领域的领军者，历经层层评选，加密矩阵有幸荣获“年度创新技术奖”奖项。获奖的背后，是业界对加密矩阵技术创新实力给予的肯定，也是加密矩阵团队全体成员辛勤耕耘的成果。</div>
                                <div className="_menu_link" >
                                    <div className='_content_button'>
                                        查看详情
                                    </div>
                                </div>
                            </Link>
                            <Link to={'/articedetail/3'} className='_news_item'>
                                <div className="_icon_border">
                                    <img className='_icon' src={require("../../assets/image/new/news-2.png")} alt=""/>
                                </div>
                                <div className='_content_title'>杭州加密矩阵科技有限公司简介</div>
                                <div className='_content_text'>杭州加密矩阵科技有限公司成立于2018年5月，是工信部审核通过的全国第二批区块链公司。</div>
                                <div className="_menu_link" >
                                    <div className='_content_button'>
                                        查看详情
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='_cooperation'>
                        <div className='_cooperation_title'>
                            <div className="_section_title">合作伙伴</div>
                            <div className="_en">/ PARTNER</div>
                            <div className="_line"></div>
                        </div>
                        <div className='_cooperation_list'>
                            <div className='_partner'>
                                <img className='_partner_icon' src={require("../../assets/image/new/pc_commerce.svg")} alt=""/>
                            </div>
                            <div className='_partner'>
                                <img className='_partner_icon' src={require("../../assets/image/new/pc_ZJUBCA.svg")} alt=""/>
                            </div>
                            <div className='_partner'>
                                <img className='_partner_icon' src={require("../../assets/image/new/pc_chinamobile.svg")} alt=""/>
                            </div>
                            <div className='_partner'>
                                <img className='_partner_icon' src={require("../../assets/image/new/pc_dell.svg")} alt=""/>
                            </div>
                            <div className='_partner'>
                                <img className='_partner_icon' src={require("../../assets/image/new/pc_servicecenter.svg")} alt=""/>
                            </div>
                            <div className='_partner'>
                                <img className='_partner_icon' src={require("../../assets/image/new/pc_sugon.svg")} alt=""/>
                            </div>
                            <div className='_partner'>
                                <img className='_partner_icon' src={require("../../assets/image/new/pc_blocklike.svg")} alt=""/>
                            </div>
                            <div className='_partner'>
                                <img className='_partner_icon' src={require("../../assets/image/new/pc_cabin.svg")} alt=""/>
                            </div>
                        </div>
                        <Slider />
                    </div>
                </section>
            </div>
            <Bottom />
        </div>
    )
}

export default withRouter(Home);

