import React ,{ Suspense,Fragment } from 'react';
import {Route} from 'react-router-dom';
import Home from './Home';
import {Icon} from 'antd';
import './main.scss';
// lazy
const Gateway = React.lazy(() => import('./Tech/Gateway'));
const LeagueChain = React.lazy(() => import('./Tech/LeagueChain'));
const Storage = React.lazy(() => import('./Tech/Storage'));
const Iot = React.lazy(() => import('./Tech/Iot'));
const ArticeDetail = React.lazy(() => import('./Article/ArticeDetail'));
const ArticleList = React.lazy(() => import('./Article/ArticleList'));
const Header = React.lazy(() => import('../assets/components/Header'));

const App = () => {
    return (
        <Fragment>
            <Suspense fallback={<Icon type="loading" />}>
                <div className="_main">
                    <Header />
                        <div className="_main_route">
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/articleList" component={ArticleList}/>
                        <Route exact path="/gateway" component={Gateway}/>
                        <Route exact path="/leaguechain" component={LeagueChain}/>
                        <Route exact path="/storage" component={Storage}/>
                        <Route exact path="/iot" component={Iot}/>
                        <Route exact path="/articedetail/:id" component={ArticeDetail}/>
                    </div>
                </div>
            </Suspense>
        </Fragment>
    )
}

export default App;
