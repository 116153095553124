import React, { Component } from "react";
import Slider from "react-slick";
import './index.scss';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block"}}
            onClick={onClick}>
            <img className='_arrow' src={require("../../image/new/right-arrow.svg")} alt=""/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block"}}
            onClick={onClick}>
            <img className='_arrow' src={require("../../image/new/left-arrow.svg")} alt=""/>
        </div>
    );
}

export default class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };
        return (
            <div>
                <Slider {...settings}>
                    <div className='_icon_wrapper'>
                        <img className='_partner_icon' src={require("../../image/new/commerce.png")} alt=""/>
                    </div>
                    <div className='_icon_wrapper'>
                        <img className='_partner_icon' src={require("../../image/new/ZJUBCA.svg")} alt=""/>
                    </div>
                    <div className='_icon_wrapper'>
                        <img className='_partner_icon' src={require("../../image/new/chinamobile.svg")} alt=""/>
                    </div>
                    <div className='_icon_wrapper'>
                        <img className='_partner_icon' src={require("../../image/new/dell.svg")} alt=""/>
                    </div>
                    <div className='_icon_wrapper'>
                        <img className='_partner_icon' src={require("../../image/new/serviceCenter.svg")} alt=""/>
                    </div>
                    <div className='_icon_wrapper'>
                        <img className='_partner_icon' src={require("../../image/new/sugon.png")} alt=""/>
                    </div>
                    <div className='_icon_wrapper'>
                        <img className='_partner_icon' src={require("../../image/new/cabin.png")} alt=""/>
                    </div>
                    <div className='_icon_wrapper'>
                        <img className='_partner_icon' src={require("../../image/new/blocklike.svg")} alt=""/>
                    </div>
                </Slider>
            </div>
        );
    }
}
