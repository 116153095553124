import React from 'react';
import './index.scss';
import './m_index.scss';

const Bottom = () => {
    return (
        <div className='_bottom_container'>
            <div className='_bottom'>
                <div className='_bottom_info'>
                    <div className='_bottom_left'>
                        <div className='_info'>地址：浙江省杭州市西湖区华星时代广场A座1108-1109室</div>
                        <div className='_info'>邮箱：hello@abmatrix.cn</div>
                    </div>
                    <div className='_bottom_right'>
                        <div className='_officialAccounts'>微信公众号</div>
                        <div className='_qrcode'>
                            <img src={require("../../image/new/qrcode.svg")} alt=""/>
                        </div>
                        <div>了解更多资讯</div>
                    </div>
                </div>
                <div className='_bottom_ICP'>
                    <a className='_ICP_link' target='_blank' rel="noopener noreferrer" href="https://beian.miit.gov.cn">杭州加密矩阵科技有限公司 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;备案号: 浙ICP备18057814号-5</a>
                </div>
            </div>
        </div>
    )
}

export default Bottom;

