import React from 'react';
import ReactDOM from 'react-dom';
import { Route, HashRouter, Switch } from 'react-router-dom';
import Main from './views/main';
// smoothscroll
import smoothscroll from 'smoothscroll-polyfill';

//配置store状态管理
import {Provider} from 'react-redux';
import { store } from './store';
import { createHashHistory } from 'history';

// css
import 'antd/dist/antd.css';
import './public.css';
import './base.scss';

export default class Root extends React.Component {

    componentDidMount() {
        const hashHistory = createHashHistory();
        // var canPoP = true; //push可能会触发pop在这节流
        hashHistory.listen(location => {
            // H5 页面跳转后回到顶部
            document.body.scrollTo(0, 0);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            // PC
            window.scrollTo(0, 0);
        })
        smoothscroll.polyfill();
    }

    render() {
        return (
            <div style={{width:"100%",height:"100%"}}>
                <HashRouter>
                    <Switch>
                        <Route path="/" component={Main} />
                    </Switch>
                </HashRouter>
            </div>
        )
    }
}

ReactDOM.render(
    <Provider store={store}>
        <Root />
    </Provider>
    ,
document.getElementById('root'));





