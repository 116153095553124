import { CLICK_MENU } from './constants';

const defaultStore = {
    status: false
}

export default (state = defaultStore, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case CLICK_MENU:
            newState.status = action.data;
            return newState;
        default:
            return state;
    }
}
